<template>
  <nav aria-label="breadcrumb">
    <ol class="flex flex-wrap items-center gap-1.5 break-words sm:gap-2.5">

      <template v-for="(item, index) in breadcrumbs">
        <li class="items-center gap-1.5 hidden md:block">
          <NuxtLink
          v-if="item.to !== route.path"
          :to="item.to"
          class="transition-colors hover:text-foreground"
          >
            {{ item.title }}
          </NuxtLink>
          <span v-else>{{ item.title }}</span>
        </li>
        <li
        v-if="index < breadcrumbs.length - 1"
        role="presentation" 
        aria-hidden="true" 
        class="[&>svg]:w-3.5 [&>svg]:h-3.5 hidden md:block"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-chevron-right-icon"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script setup>
const optionsStore = useOptionsStore()
const { breadcrumbs } = storeToRefs(optionsStore)
const route = useRoute()
</script>